const videoData = [
  {
    id: 12,
    title: '"La Vie En Rose"',
    titleAlt: "Downtown Disney - Jazz Kitchen",
    description: "Édith Piaf",
    youtubeURL: "https://www.youtube.com/embed/hrKuEBrXHgA?si=XRVch7JCr3C9Epk2",
  },
  {
    id: 11,
    title: '"Memory"',
    titleAlt: "",
    description: "Cats",
    youtubeURL: "https://www.youtube.com/embed/3oHb1aYR8lc?si=cy2J6jho_n6g_rTr",
  },
  {
    id: 10,
    title: '"Time To Say Goodbye"',
    titleAlt: "",
    description: "Andrea Bocelli and Sarah Brightman",
    youtubeURL: "https://www.youtube.com/embed/CVsuwA8grG8?si=bDmRRH0S3bIqktWi",
  },
  {
    id: 9,
    title: '"Edelweiss"',
    titleAlt: "",
    description: "The Sound of Music",
    youtubeURL: "https://www.youtube.com/embed/HBDBNt_m5i8?si=2J9RAyytPxJTV8dT",
  },
  {
    id: 8,
    title: '"So This is Love"',
    titleAlt: "",
    description: "Cinderella",
    youtubeURL: "https://www.youtube.com/embed/bA__a4na_iE?si=nMZ9d5l4SY_MY4DR",
  },
  {
    id: 7,
    title: '"Part of Your World"',
    titleAlt: "",
    description: "The Little Mermaid",
    youtubeURL: "https://www.youtube.com/embed/yGveayDyB7E?si=pT8VMNeCFDBolKVE",
  },
  {
    id: 6,
    title: "Vocal Highlights",
    titleAlt: "",
    description: "Lucienne Franco",
    youtubeURL: "https://www.youtube.com/embed/hd81mRy906M?si=zjIsgpccc0HFt6aD",
  },
  {
    id: 5,
    title: "Live Music at Cooper Ridge",
    titleAlt: "",
    description: "Lucienne Franco",
    youtubeURL: "https://www.youtube.com/embed/H91dTUvVoFA?si=eWaVYykuoIxT7V9s",
  },
  {
    id: 4,
    title: '"Roméo et Juliette"',
    titleAlt: "",
    description: "Act I, Je veux vivre",
    youtubeURL: "https://www.youtube.com/embed/su5YZa_3Pn0?si=DPfx_ADPMaj_W9Qn",
  },
  {
    id: 3,
    title: '"On My Own"',
    titleAlt: "",
    description: "Les Misérables",
    youtubeURL: "https://www.youtube.com/embed/9yH3bFhrDQ0?si=7aL13oagIm-EoW60",
  },
  {
    id: 2,
    title: '"All I Ask of You"',
    titleAlt: "Duet with Michael Washington",
    description: "The Phantom of the Opera",
    youtubeURL: "https://www.youtube.com/embed/PAuSeeNfZDU?si=kpAYAIWHBI1i82yj",
  },
  {
    id: 1,
    title: '"Think of Me"',
    titleAlt: "",
    description: "The Phantom of the Opera",
    youtubeURL: "https://www.youtube.com/embed/RaSTXPfj4NY?si=TFJpPx-luQH-INTh",
  },
];

export default videoData;
