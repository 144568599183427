const upcomingEventData = [

  {
    id: 10,
    title: "Vocal Performance",
    day: "August 31",
    year: "2025",
    time: "1:00 PM - 4:00 PM",
    venue: "Cooper Ridge Vineyard",
    address1: "1389 Old Garden Valley Rd",
    address2: "Roseburg, OR 97471",
    website: "https://www.cooperridgevineyard.com/",
  },
];

const pastEventData = [
 { 
    id: 9,
    title: "Live Piano and Voice",
    day: "January 18",
    year: "2025",
    time: "12:00 PM - 1:00 PM",
    venue: "Downtown Disney - Jazz Kitchen",
    address1: "1590 Disneyland Dr",
    address2: "Anaheim, CA 92802",
    website: "https://www.jazzkitchencoastalgrill.com/",
  },
  {
    id: 8,
    title: "Vocal Performance",
    day: "August 11",
    year: "2024",
    time: "1:00 PM - 4:00 PM",
    venue: "Cooper Ridge Vineyard",
    address1: "1389 Old Garden Valley Rd",
    address2: "Roseburg, OR 97471",
    website: "https://www.cooperridgevineyard.com/",
  },
  {
    id: 7,
    title: "Vocal Performance",
    day: "August 9",
    year: "2024",
    time: "1:00 PM - 3:00 PM",
    venue: "Cooper Ridge Vineyard",
    address1: "1389 Old Garden Valley Rd",
    address2: "Roseburg, OR 97471",
    website: "https://www.cooperridgevineyard.com/",
  },

  {
    id: 6,
    title: "Vocal Performance",
    day: "July 23",
    year: "2023",
    time: "1:00 PM - 4:00 PM",
    venue: "Cooper Ridge Vineyard",
    address1: "1389 Old Garden Valley Rd",
    address2: "Roseburg, OR 97471",
    website: "https://www.cooperridgevineyard.com/",
  },
  {
    id: 5,
    title: "Vocal Performance",
    day: "July 21",
    year: "2023",
    time: "1:00 PM - 3:00 PM",
    venue: "Cooper Ridge Vineyard",
    address1: "1389 Old Garden Valley Rd",
    address2: "Roseburg, OR 97471",
    website: "https://www.cooperridgevineyard.com/",
  },
  {
    id: 4,
    title: "Carrie the Musical #3",
    day: "August 10",
    year: "2019",
    time: "8:00 PM - 10:30 PM",
    venue: "The Forum Theater",
    address1: "4175 Fairmont Blvd",
    address2: "Yorba Linda, CA 92886",
    website: "",
  },
  {
    id: 3,
    title: "Carrie the Musical #2",
    day: "August 9",
    year: "2019",
    time: "8:00 PM - 10:30 PM",
    venue: "The Forum Theater",
    address1: "4175 Fairmont Blvd",
    address2: "Yorba Linda, CA 92886",
    website: "",
  },
  {
    id: 2,
    title: "Carrie the Musical #1",
    day: "August 8",
    year: "2019",
    time: "8:00 PM - 10:30 PM",
    venue: "The Forum Theater",
    address1: "4175 Fairmont Blvd",
    address2: "Yorba Linda, CA 92886",
    website: "",
  },
  {
    id: 1,
    title: "Senior Recital",
    day: "March 8",
    year: "2018",
    time: "8:00 PM - 10:30 PM",
    venue: "Salmon Recital Hall",
    address1: "Chapman University",
    website: "https://www.chapman.edu/copa/facilities.aspx",
  },
];

export { pastEventData, upcomingEventData };
